import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDailogContent,
  styled,
} from '@mui/material';

import { transferEndpoints, transferLocators } from '../../../apis/deviceApi';
import { getWho } from '../../../apis/userApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  setTransferDeviceMap,
  setTransferEmail,
  setTransferReserveSetting,
} from '../../../slices/systemSlice';

import CrossSvgIcon from '../../../components/SvgIcon/CrossSvgIcon';

import { parseDasId } from '../../../utils/common';

import ConfirmEmail from './ConfirmEmail';
import ReserveSettingContent from './ReserveSettingContent';
import TransferDeviceContent from './TransferDeviceContent';
import TransferDeviceResultContent from './TransferDeviceResultContent';

const Dialog = styled(MuiDialog)`
  & .MuiPaper-root {
    width: 100%;
    max-width: 480px;
    .header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 8px 10px;

      & > .icon {
        cursor: pointer;
      }
    }
  }
`;

const DialogContent = styled(MuiDailogContent)`
  padding: 0;
`;

const TransferDeviceDialog = () => {
  const [step, setStep] = useState(1);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const transferDeviceMap = useAppSelector(
    (store) => store.system.transferDeviceMap,
  );
  const transferReserveSetting = useAppSelector(
    (store) => store.system.transferReserveSetting,
  );
  const transferEmail = useAppSelector((store) => store.system.transferEmail);
  const [successCount, setSuccessCount] = useState(0);
  const [failureCount, setFailureCount] = useState(0);

  const devices = Object.entries(transferDeviceMap)
    .filter(([, value]) => !!value?.isSelected)
    .map<{ id: string; dasId: string }>(([dasId, value]) => ({
      id: value?.id as string,
      dasId,
    }));

  const handleClickCancel = () => {
    dispatch(setTransferDeviceMap({}));
    dispatch(setTransferEmail(null));
    dispatch(setTransferReserveSetting(true));
    navigate(-1);
  };

  const transferDevices = async () => {
    setSuccessCount(0);
    setFailureCount(0);

    const userRes = await getWho(transferEmail);

    for (const device of devices) {
      const deviceType = parseDasId(device.dasId);
      try {
        if (deviceType === 'dasbeacon') {
          await transferLocators({
            destinationUserId: userRes.data.data.id,
            bleAoaIds: [],
            dasbeaconIds: [device.id],
            uwbAoaIds: [],
            clearBinding: !transferReserveSetting,
          });
        } else if (deviceType === 'ble_aoa') {
          await transferLocators({
            destinationUserId: userRes.data.data.id,
            bleAoaIds: [device.id],
            dasbeaconIds: [],
            uwbAoaIds: [],
            clearBinding: !transferReserveSetting,
          });
        } else if (deviceType === 'uwb_aoa') {
          await transferLocators({
            destinationUserId: userRes.data.data.id,
            bleAoaIds: [],
            dasbeaconIds: [],
            uwbAoaIds: [device.id],
            clearBinding: !transferReserveSetting,
          });
        } else {
          await transferEndpoints({
            destinationUserId: userRes.data.data.id,
            endpointIds: [device.id],
            clearBinding: !transferReserveSetting,
          });
        }
        setSuccessCount((count) => count + 1);
      } catch (error) {
        if (!(error instanceof Error)) {
          return;
        }
        setFailureCount((count) => count + 1);
        continue;
      }
    }

    setStep(4);
  };

  const handleNext = () => {
    setStep(step + 1);
    if (step + 1 === 3) {
      transferDevices();
    }
  };

  return (
    <Dialog open>
      <div className="header">
        {step !== 3 && (
          <div className="icon" onClick={handleClickCancel}>
            <CrossSvgIcon
              sx={{
                display: 'block',
                width: 32,
                height: 32,
              }}
            />
          </div>
        )}
      </div>
      <DialogContent>
        {step === 1 && (
          <ConfirmEmail onNext={handleNext} onCancel={handleClickCancel} />
        )}
        {step === 2 && (
          <ReserveSettingContent
            onNext={handleNext}
            onCancel={handleClickCancel}
          />
        )}
        {step === 3 && (
          <TransferDeviceContent
            successCount={successCount}
            failureCount={failureCount}
            totalCount={devices.length}
          />
        )}
        {step === 4 && (
          <TransferDeviceResultContent
            successCount={successCount}
            totalCount={devices.length}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TransferDeviceDialog;
